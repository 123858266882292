import intlTelInpututility from "intl-tel-input/build/js/utils";

$(document).on('turbolinks:before-cache', function (e) {
    $(".dashboard-calendar, .datepicker-inline, .calendar-inline").flatpickr('destroy');
    $("#studentModal").modal('hide').removeClass("in show");
    $(".modal-backdrop").remove();
    $(".listing-slider").slick("unslick");
    $(".listing-slider").slick("reinit");
});

$(document).ajaxSend(function (event, jqXHR, settings) {
    if (settings.url.split("?")[0] != "/activities/notification") {
        if (settings.url != "/homes/search_course") {
            $("#page-overlay").fadeIn(300);
        }
        else {
            $(".cv-spinner").fadeIn(300);
        }
    }
});
$(document).ajaxSuccess(function (event, jqXHR, settings)  {
    if (settings.url != "/homes/search_course") {
        setTimeout(function () {
            $("#page-overlay").fadeOut(300);
        }, 500);
    }
    else {
        setTimeout(function () {
            $(".cv-spinner").fadeOut(300);
        }, 500);
    }

});
// $( document ).on('turbolinks:click', function() {
//     $("#page-overlay").fadeIn(300);
// });
// $( document ).on('turbolinks:before-cache', function() {
//     setTimeout(function(){
//         $("#page-overlay").fadeOut(300);
//     },500);
// });

$(document).on('turbolinks:load', function () {

    $('#promo-code-btn').click(function () {
        if ($('#promo_code').val() != "") {
            $.ajax({
                url: '/carts/' + $('#cart_number').val() + '/set_promo_code',
                type: 'post',
                dataType: "script",
                async: false,
                data: {code: $('#promo_code').val()},
                success: function (response) {

                },
                error: function (response) {

                }
            });
        }
    })

    $('.select2').select2();
    $('.listing-slider').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        autoplay: false,
        touchMove: true,
        speed: 500,
        dots: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false,
                    arrows: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: false,
                    arrows: false,
                    centerMode: true,
                    centerPadding: '80px'
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                    arrows: false,
                    centerMode: true,
                    centerPadding: '80px'
                }
            }
        ]
    });

    $("<hr class='mx-3'>").insertAfter($(".flatpickr-calendar").find('.flatpickr-innerContainer .flatpickr-rContainer .flatpickr-weekdays'));

    $("#changeEmailLink").on("click", function () {
        $("#changeEmail").removeClass('d-none');
    });

    PhoneDisplay(document.querySelector("#user_mobile_no"));
    studentaddressLookup();


    $("#user_date_of_birth,.datepicker").flatpickr({
        dateFormat: "m-d-Y",
        maxDate: Date.now()
    });

    $(".booking_datepicker").flatpickr({
        dateFormat: "Y-m-d",
        minDate: Date.now()
    });

    $(".datepicker-inline").flatpickr({
        dateFormat: "Y-m-d",
        inline: true
    });

    $("#search-btn").on("keyup", function () {
        if ($(this).val() == "") {
            $("#search-suggestion-course, #search-suggestion-user").empty();
        } else {
            $.ajax({
                type: "POST",
                dataType: "script",
                url: '/homes/search_course',
                data: {search: {title: $(this).val()}},
                success: function (data) {
                }
            });
        }
    });

    $("#EditForm").validate({
        rules: {
            "user[date_of_birth]": {
                required: true
            }
        }
    });

    $('.course-session').on('cocoon:after-insert', function (e, insertedItem, originalEvent) {
        $(".course-date").flatpickr({
            minDate: 'today',
            dateFormat: "Y-m-d"
        });
        setTime(insertedItem.find('.end-time'), insertedItem.find('.start-time'))
    });

    $(".search-conv").on("keyup", function () {
        $.ajax({
            type: "POST",
            dataType: "script",
            url: '/conversations/search',
            data: {query: $(this).val()},
            success: function (data) {
            }
        });
    });

    $(".toggle-password-in").on("focus", function () {
        $(this).siblings('.toggle-password').removeClass("show hide");
        $(this).attr("type", "text");
        $(this).closest('div').find('span .fal').removeClass('fa-eye-slash');
        $(this).closest('div').find('span .fal').addClass('fa-eye');
        $(this).siblings('.toggle-password').addClass("hide");
    });

    $(".toggle-password-in").on("focusout", function () {
        $(this).siblings('.toggle-password').removeClass("show hide");
        $(this).attr("type", "password");
        $(this).closest('div').find('span .fal').removeClass('fa-eye');

        $(this).closest('div').find('span .fal').addClass('fa-eye-slash');
        $(this).siblings('.toggle-password').addClass("show");
    });

    $(".toggle-password").click(function () {
        $(this).toggleClass("show hide");
        var input = $($(this).data("toggle"));

        if (input.attr("type") == "password") {
            input.closest('div').find('span .fal').removeClass('fa-eye-slash');
            input.closest('div').find('span .fal').addClass('fa-eye');
            input.attr("type", "text");
        } else {
            input.closest('div').find('span .fal').addClass('fa-eye-slash');
            input.closest('div').find('span .fal').removeClass('fa-eye');
            input.attr("type", "password");
        }
    });

});

export function setEvent(date) {
    $(".dashboard-calendar").flatpickr({
        dateFormat: "Y-m-d",
        inline: true,
        defaultDate: "today",
        monthSelectorType: 'static',
        onReady: function (selectedDates, dateStr, instance) {
            $(".flatpickr-months").find(".numInputWrapper").attr('style', 'display:none');
            instance.monthElements[0].textContent += instance.currentYear;
        },
        onMonthChange: function (selectedDates, dateStr, instance) {
            $(".flatpickr-months").find(".numInputWrapper").attr('style', 'display:inline-block');
            instance.monthElements[0].textContent += instance.currentYear;
        },
        onChange: function (selectedDates, dateStr, instance) {
            $(".flatpickr-months").find(".numInputWrapper").attr('style', 'display:none');
            instance.monthElements[0].textContent += instance.currentYear;
            $.each(date, function (index, value) {
                if (value == dateStr) {
                    $.ajax({
                        url: '/students/dashboards/set_date_event',
                        type: 'get',
                        dataType: "script",
                        async: false,
                        data: {select_date: value},
                        success: function (response) {

                        },
                        error: function (response) {

                        }
                    });
                }
            })

        },
        onDayCreate: function (dObj, dStr, fp, dayElem) {
            $.each(date, function (index, value) {

                if (value == moment(dayElem.dateObj).format('YYYY-MM-DD')) {
                    dayElem.innerHTML += "<span class='noti-dot bg-secondary'></span>";
                }
            });
        }
    });
}

function PhoneDisplay(input) {
// Set Phone Number Country Code Validations
    var errorMsg = document.querySelector(".error-msg")

    if (input) {
        // here, the index maps to the error code returned from getValidationError - see readme
        var errorMap = ["Invalid number", "Invalid country code", "Too short", "Too long", "Invalid number"];

        // initialise plugin
        var iti = window.intlTelInput(input, {
            initialCountry: "us",
            separateDialCode: true,
            hiddenInput: "mobile_no",
            utilsScript: intlTelInpututility
        });
        var reset = function () {
            input.classList.remove("error");
            input.closest('.form-group').getElementsByClassName('error-msg')[0].innerHTML = ""
        };


        // on blur: validate
        input.addEventListener('blur', function () {
            reset();
            if (input.value.trim()) {
                if (iti.isValidNumber()) {
                    $('.mobile-number').closest("form").find('button[type="submit"]').attr("disabled", false);
                } else {
                    input.classList.add("error");
                    var errorCode = iti.getValidationError();
                    input.closest('.form-group').getElementsByClassName('error-msg')[0].innerHTML = errorMap[errorCode];
                    $('.mobile-number').closest("form").find('button[type="submit"]').attr("disabled", true);
                }
            }
        });

        input.addEventListener("countrychange", function (e, countryData) {
        });
        // on keyup / change flag: reset
        input.addEventListener('change', reset);
        input.addEventListener('keyup', reset);
    }
}


function studentaddressLookup() {
    $('.student-location').each(function () {
        var options = {
            types: ['(cities)']
        };
        var places = new google.maps.places.Autocomplete(this, options);
        places.setComponentRestrictions({
            country: ["us"],
        });
        var countryclass =($(this).data('countryclass'));
        var cityclass =($(this).data('cityclass'));
        var stateclass =($(this).data('stateclass'));
        var zipclass =($(this).data('zipclass'));
        var latclass =($(this).data('latclass'));
        var lngclass =($(this).data('logclass'));
        var streetclass =($(this).data('streetclass'));

        google.maps.event.addListener(places, 'place_changed', function () {
            var place = places.getPlace();
            if (place.geometry) {
                var lat = place.geometry.location.lat();
                var lng = place.geometry.location.lng();
            }

            for (var i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];
                console.log(addressType, place.address_components[i])
                if (addressType  == "locality") {
                    var city = place.address_components[i]['long_name'];
                }else if (addressType  == "administrative_area_level_1") {
                    var state = place.address_components[i]['short_name'];
                }else if (addressType  == "postal_code") {
                    var postalcode = place.address_components[i]['long_name'];
                }else if (addressType  == "street_number") {
                    var snumber = place.address_components[i]['long_name'];
                }else if (addressType  == "route") {
                    var route = place.address_components[i]['long_name'];
                }else if (addressType  == "country") {
                    var countryname = place.address_components[i]['short_name'];
                }
            }

            if (cityclass && stateclass) {
                $('.' + cityclass).val(city);
                $('.' + stateclass).val(state);
            }
            if (zipclass) {
                $('.' + zipclass).val(postalcode);
            }
            if (countryclass) {
                $('.' + countryclass).val(countryname);
            }
            if (latclass && lngclass && lat && lng) {
                $('.' + latclass).val(lat);
                $('.' + lngclass).val(lng);
            }
            if (streetclass){
                var street = ''
                console.log(snumber)
                console.log(route)
                if (typeof snumber != "undefined") {
                    street += snumber
                }
                if (typeof route != "undefined") {
                    street += (" " + route)
                }
                $('.'+streetclass).val(street);
            }
            if(city){
                $('#user_city').val(city);
                $(".student-state").val(state);
            }

        });

        google.maps.event.addDomListener(this, 'keydown', function (event) {
            if (event.keyCode === 13) {
                event.preventDefault();
            }
        });
    });
}

export function customSharePopup(url) {
    window.open(url, "share_dialog", "width=626,height=450");
}
