$(document).on('turbolinks:load', function () {
    var promo_end_date = $('.promo-end-date').flatpickr({
        minDate: 'today',
        dateFormat: "Y-m-d"
    })

    if ($('.iframe-tag').length == 1) {
        let url = $('.iframe-tag').attr('data-value');
        if (url.includes("youtube")) {
            const a = new URL(url)
            const id = a.searchParams.get("v")
            $('.iframe-tag').append('<iframe src="//www.youtube.com/embed/' + id + '"></iframe')
        } else {
            const videoId = new URL(url).pathname.slice(1)
            $('.iframe-tag').append('<iframe src="//player.vimeo.com/video/' + videoId + '"></iframe')        }
    }

    $('.promo-start-date').flatpickr({
        minDate: 'today',
        dateFormat: "Y-m-d",
        onChange: function (selectedDates, dateStr, instance) {
            if (typeof promo_end_date != 'undefined') {
                promo_end_date.set('minDate', dateStr)
                $('.promo-end-date').val('')
            }
        }
    })

    $('.video-link').on('focusout', function () {
        if (!($(this).val().includes("youtube") || $(this).val().includes("vimeo"))) {
            $(this).closest('form').find('.lesson-submit').addClass('disabled')
            $(this).closest('form').find('.url-error').removeClass('d-none').html("Please add youtube or vimeo url");
        } else {
            $(this).closest('form').find('.lesson-submit').removeClass('disabled')
            $(this).closest('form').find('.url-error').addClass('d-none')
        }
    })

    $('.previous-quiz').on('click', function (){
        var index_one = $(this).attr('data-index-one')
        var index = $(this).attr('data-index')
        $('.single_ques_'+ index_one).removeClass('d-none');
        $('.single_ques_'+ index).addClass('d-none');
    })

    $('.next-quiz').on('click', function (){
        var index_one = $(this).attr('data-index-one')
        var index = $(this).attr('data-index')
        $('.single_ques_'+ index_one).removeClass('d-none');
        $('.single_ques_'+ index).addClass('d-none')
    })

    $('.read-more').on('click', function () {
        var trucated = $(this).attr('data-truncated')
        var value = $(this).attr('data-value')
        if ($('.read-more').text() == "Read More ") {
            $(this).parent().find('.more-text').html(value)
            $(this).html("<span>Read Less <i class='fal fa-chevron-up ml-2'></i></span>")
        } else {
            $(this).parent().find('.more-text').html(trucated)
            $(this).html("<span>Read More <i class='fal fa-chevron-down ml-2'></i></span>")
        }
    })

    $('[data-toggle="tooltip"]').tooltip();
    $(document).on("keyup", "#course_price, #course_discount", function () {
        var original_price = $('#course_price').val();
        var discount = $('#course_discount').val();

        if ((discount > 100) || (discount == 0)) {
            $('#course_final_price').val(original_price);
            $('.price').addClass('d-none');
        } else {
            var price = (discount / 100) * original_price;
            var final_price = (original_price - price).toFixed(2);
            $('.price').removeClass('d-none');
            $('.save-price').html(price.toFixed(2));
            $('#course_final_price').val(final_price)
        }
    });
    $(document).on("keyup", "#tutor_service_price, #tutor_service_discount", function () {
        var original_price = $('#tutor_service_price').val();
        var discount = $('#tutor_service_discount').val();

        if ((discount > 100) || (discount == 0)) {
            $('#tutor_service_final_price').val(original_price);
            $('.price').addClass('d-none');
        } else {
            var price = (discount / 100) * original_price;
            var final_price = (original_price - price).toFixed(2);
            $('.price').removeClass('d-none');
            $('.save-price').html(price.toFixed(2));
            $('#tutor_service_final_price').val(final_price)
        }
    });

    $("table[role='datatable']").each(function (e) {
        var oTable = $(this).DataTable({
            bProcessing: true,
            bServerSide: true,
            bLengthChange: true,
            bInfo: true,
            language: {search: "", searchPlaceholder: "Search..."},
            "ordering": true,
            "order": [[0, "asc"]],
            "aoColumnDefs": [
                {'bSortable': false, 'aTargets': [-1, "no-sort"]}
            ],
            sAjaxSource: $(this).data('source')
        });
    });

    $(".course-date").flatpickr({
        minDate: 'today',
        dateFormat: "Y-m-d",
    });

    $(".event-date").flatpickr({
        minDate: 'today',
        dateFormat: "Y-m-d",
    });

    // $(".start-time, .end-time").flatpickr({
    //     noCalendar: true,
    //     dateFormat: "h:i K",
    //     enableTime: true,
    //
    // });

    $(document).on("change", ".category_wise_sub_cate", function () {
        get_sub_category($(this).val());
    });
    $(document).on("change", "#course_category_id", function () {
        get_sub_category($(this).val());
    });

    addressLookup();
    init_filepond_single();
    init_filepond_multiple();
    init_filepond_profile_pic();
    // init_filepond_media();
    setTime($('.end-time'), $('.start-time'));

    $('.editor-container').each(function () {
        var field_name = this.getAttribute('data-name')
        var quill = new Quill(this, {
            modules: {
                toolbar: [
                    [{header: [1, 2, false]}],
                    ['bold', 'italic', 'underline'],
                    ['code-block']
                ]
            },
            placeholder: 'Compose an epic...',
            theme: 'snow'  // or 'bubble'
        });
        quill.on('text-change', async function (e) {
            let justHtml = quill.root.innerHTML;
            $("input[name='" + field_name + "']").val(justHtml);
        });
    });


});

function addressLookup() {
    $('.g-location').each(function () {
        var options = {};
        var places = new google.maps.places.Autocomplete(this, options);
        places.setComponentRestrictions({
            country: ["us"],
        });
        var countryclass = ($(this).data('countryclass'));
        var cityclass = ($(this).data('cityclass'));
        var stateclass = ($(this).data('stateclass'));
        var zipclass = ($(this).data('zipclass'));
        var latclass = ($(this).data('latclass'));
        var lngclass = ($(this).data('logclass'));
        var streetclass = ($(this).data('streetclass'));

        google.maps.event.addListener(places, 'place_changed', function () {
            var place = places.getPlace();
            if (place.geometry) {
                var lat = place.geometry.location.lat();
                var lng = place.geometry.location.lng();
            }

            for (var i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];
                console.log(addressType, place.address_components[i])
                if (addressType == "locality") {
                    var city = place.address_components[i]['long_name'];
                } else if (addressType == "administrative_area_level_1") {
                    var state = place.address_components[i]['short_name'];
                } else if (addressType == "postal_code") {
                    var postalcode = place.address_components[i]['long_name'];
                } else if (addressType == "street_number") {
                    var snumber = place.address_components[i]['long_name'];
                } else if (addressType == "route") {
                    var route = place.address_components[i]['long_name'];
                } else if (addressType == "country") {
                    var countryname = place.address_components[i]['short_name'];
                }
            }

            if (cityclass && stateclass) {
                $('.' + cityclass).val(city);
                $('.' + stateclass).val(state);
            }
            if (zipclass) {
                $('.' + zipclass).val(postalcode);
            }
            if (countryclass) {
                $('.' + countryclass).val(countryname);
            }
            if (latclass && lngclass && lat && lng) {
                $('.' + latclass).val(lat);
                $('.' + lngclass).val(lng);
            }
            if (streetclass) {
                var street = ''
                console.log(snumber)
                console.log(route)
                if (typeof snumber != "undefined") {
                    street += snumber
                }
                if (typeof route != "undefined") {
                    street += (" " + route)
                }
                $('.' + streetclass).val(street);
            }

        });

        google.maps.event.addDomListener(this, 'keydown', function (event) {
            if (event.keyCode === 13) {
                event.preventDefault();
            }
        });
    });
}

export function setTime(end_time, start_time) {
    var r_end_time;
    var increment = 15;
    if ($('#course_course_hour').val() != '') {
        increment = $('#course_course_hour').val()
    }
    r_end_time = end_time.flatpickr(
        {
            enableTime: true,
            noCalendar: true,
            dateFormat: "h:i K",
            minuteIncrement: increment
        });
    start_time.flatpickr(
        {
            enableTime: true,
            noCalendar: true,
            dateFormat: "h:i K",
            minuteIncrement: increment,
            onOpen: function (selectedDates, dateStr, instance) {
                if (typeof r_end_time != 'undefined') {
                    var select_date_obj = moment().add(1, 'hours')
                    if (dateStr != '') {
                        select_date_obj = moment(dateStr, "hh:mm a").add(increment, 'minutes')
                    }
                    // r_end_time.set('minTime', select_date_obj.format("HH")+":"+select_date_obj.format("mm"));
                    // r_end_time.set('defaultHour', select_date_obj.format("HH"));
                    // r_end_time.set('defaultMinute', select_date_obj.format("mm"));
                    end_time.flatpickr(
                        {
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: "h:i K",
                            minuteIncrement: increment,
                            minTime: select_date_obj.format("HH") + ":" + select_date_obj.format("mm"),
                            defaultHour: select_date_obj.format("HH"),
                            defaultMinute: select_date_obj.format("mm")
                        });
                }

            },
            onChange: function (selectedDates, dateStr, instance) {
                if (typeof r_end_time != 'undefined') {
                    var select_date_obj = moment().add(1, 'hours')
                    if (dateStr != '') {
                        select_date_obj = moment(dateStr, "hh:mm a").add(increment, 'minutes')
                    }
                    console.log(select_date_obj.format("HH"))
                    // r_end_time.set('minTime', select_date_obj.format("HH")+":"+select_date_obj.format("mm"));
                    // r_end_time.set('defaultHour', 15);
                    // r_end_time.set('defaultMinute', select_date_obj.format("mm"));
                    $('.end-time').val("")
                    end_time.flatpickr(
                        {
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: "h:i K",
                            minuteIncrement: increment,
                            minTime: select_date_obj.format("HH") + ":" + select_date_obj.format("mm"),
                            defaultHour: select_date_obj.format("HH"),
                            defaultMinute: select_date_obj.format("mm")
                        });
                }
            }
            // onChange: function (selectedDates, dateStr, instance) {
            //     alert("ca;ll")
            //     if (typeof r_end_time != 'undefined') {
            //         alert(moment(dateStr, "h:mm A").format("HH:mm"))
            //         r_end_time.set('minTime', moment(dateStr, "h:mm A").format("HH:mm"));
            //         r_end_time.set('defaultHour', moment(dateStr, "h:mm A").format("HH"));
            //         r_end_time.set('defaultMinute', moment(dateStr, "h:mm A").format("mm"));
            //     }
            // }
        }
    )
}

export function get_sub_category(category_id) {
    $.ajax({
        type: "POST",
        dataType: "script",
        url: '/categories/get_sub_category',
        data: {category_id: category_id},
        success: function (data) {
        }
    });
}
