// app/javascript/packs/course_form.js

function addOutcome() {
    const container = document.getElementById('outcomes-container');
    const template = container.querySelector('.outcome-input-template');
    const newInput = template.cloneNode(true);
    newInput.classList.remove('outcome-input-template', 'tw-hidden');
    
    // Insert before the Add outcome button
    container.insertBefore(newInput, container.lastElementChild);
    
    // Focus the new input
    newInput.querySelector('input').focus();
    
    updatePlaceholders('outcomes-container', 'Key outcome');
  }
  
  function removeOutcome(button) {
    const container = document.getElementById('outcomes-container');
    button.parentElement.remove();
    updatePlaceholders('outcomes-container', 'Key outcome');
  }
  
  function addAudience() {
    const container = document.getElementById('audiences-container');
    const template = container.querySelector('.audience-input-template');
    const newInput = template.cloneNode(true);
    newInput.classList.remove('audience-input-template', 'tw-hidden');
    
    // Insert before the Add audience button
    container.insertBefore(newInput, container.lastElementChild);
    
    // Focus the new input
    newInput.querySelector('input').focus();
    
    updatePlaceholders('audiences-container', 'Target audience');
  }
  
  function removeAudience(button) {
    const container = document.getElementById('audiences-container');
    button.parentElement.remove();
    updatePlaceholders('audiences-container', 'Target audience');
  }
  
  function updatePlaceholders(containerId, baseText) {
    const container = document.getElementById(containerId);
    const inputs = container.querySelectorAll('input[type="text"]:not(.tw-hidden)');
    inputs.forEach((input, index) => {
      input.placeholder = `${baseText} ${index + 1}`;
    });
  }
  
  // Initialize placeholders on page load
  document.addEventListener('DOMContentLoaded', () => {
    updatePlaceholders('outcomes-container', 'Key outcome');
    updatePlaceholders('audiences-container', 'Target audience');
  });